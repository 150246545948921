import React, { useEffect } from 'react'
import useMarkAttendance from '../hooks/useMarkAttendance'
import { EButton, EEmpty, StatCard } from '../../../../../../libs/ui'
import { Badge, Card, Grid, Skeleton } from '@mantine/core'
import InfoLabel from '../../../../../../libs/ui/src/InfoLabel/InfoLabel'
import moment from 'moment'
import { AiFillLike } from 'react-icons/ai'
import { ITeacherMarkAttendanceList } from '../../../../../../libs/data-access/types'
import { BsStar } from 'react-icons/bs'
import { CSVLink } from 'react-csv'
import { BiDownload } from 'react-icons/bi'

type IProps = {
  isLoading: boolean;
  teacherMarkAttendanceList: ITeacherMarkAttendanceList[];
  index: any;
  getTeacherTrainingAttendanceStatus: any;
  isLoadingAttendanceStatus:any;
};
export default function AttendanceList({
  isLoading,
  teacherMarkAttendanceList,
  index,
  getTeacherTrainingAttendanceStatus,
  isLoadingAttendanceStatus,
}: IProps) {

  const timeSlots = [
    { start: 8.5, end: 10, label: "8:30 am - 10:00 am" },
    { start: 13.5, end: 14.5, label: "1:30 pm - 2:30 pm" },
    { start: 16, end: 17, label: "4:00 pm - 5:00 pm" },
  ];

  // Function to determine the slot for a given attendance date
  function getAttendanceSlot(attendanceDate:any) {
    const date = new Date(attendanceDate);

    // Convert attendance time into a decimal for comparison
    const attendanceTime = date.getHours() + date.getMinutes() / 60;

    // Find the slot that matches the attendance time
    const slot = timeSlots.find(
      (slot) => attendanceTime >= slot.start && attendanceTime < slot.end
    );

    if (slot) {
      return `${slot.label}`;
    } else {
      return "Out of slot.";
    }
  }
  return (
    <Grid className="mt-2">
      <Grid.Col span={6}>
        {isLoading ? (
          <Skeleton height={190} radius="md" />
        ) : !teacherMarkAttendanceList?.length ? (
          <EEmpty title="Attendance has been not mark yet" />
        ) : (
          teacherMarkAttendanceList?.map((e, childIndex) => (
            <Card className="bg-slate-50 p-4 mt-3">
              {/* <InfoLabel
                                        textSize='md'
                                        label='id'
                                        value={e?.training_Schedule_Attendance_Id ?? "-"} /> */}
              <div className="flex justify-between items-center">
                <div>
                  <InfoLabel
                    textSize="md"
                    label="Slot"
                    value={getAttendanceSlot(e?.attendance_Date) ?? "-"}
                  />
                  <div className="flex justify-between">
                    <InfoLabel
                      textSize="md"
                      label="Title"
                      value={e?.training_Title ?? "-"}
                    />
                    {e?.is_Attendance_Done ? (
                      <></>
                    ) : (
                      <Badge color="green">
                        <span className="flex gap-2">
                          Latest Attendance <BsStar />
                        </span>
                      </Badge>
                    )}
                  </div>
                  <InfoLabel
                    label="Mark Attendance Time"
                    value={
                      e?.attendance_Date
                        ? moment(e?.attendance_Date).format(
                            "DD-MM-YYYY hh:mm A"
                          )
                        : "-"
                    }
                  />
                </div>
                {/* 
                  <CSVLink
                    style={{}}
                    className="text-blue-600 text-sm hover:underline ml-auto"
                    data={teacherTrainingAttendanceStatus}
                    asyncOnClick={true}
                    onClick={() =>
                      getTeacherTrainingAttendanceStatus(e?.attendance_Date)
                    }
                    filename={`Attendance_Details${moment().format(
                      "MMM"
                    )}-${moment().format("YYYY")}.csv`}
                  > */}
                {/* {console.log("moment(e?.attendance_Date).isBefore(moment())",moment(e?.attendance_Date).isBefore(moment(), "day"))} */}
                {moment(e?.attendance_Date).isBefore(moment(), "day") ? (
                  <EButton
                    className="btn-blue"
                    loading={isLoadingAttendanceStatus && index == childIndex}
                    onClick={() =>
                      getTeacherTrainingAttendanceStatus(
                        e?.attendance_Date,
                        childIndex
                      )
                    }
                  >
                    Download <BiDownload className="ml-2" size={22} />
                  </EButton>
                ) : null}
              </div>
            </Card>
          ))
        )}
      </Grid.Col>
      <Grid.Col span={1}></Grid.Col>
      {/* <InfoLabel label='Training place' value={selectedSchedule?.training_Place ?? "-"} /> */}
    </Grid>
  );
}
