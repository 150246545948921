import React, { useEffect } from 'react'
import PageLayout from '../../../../PageLayout'
import { EButton, EPageTitle, ESectionDivider, ETable } from '../../../../libs/ui'
import { Select, MultiSelect, TextInput, Checkbox, Textarea } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import useSchedulePeriodicAssessment from '../Hooks/useSchedulePeriodicAssessment';
import moment from 'moment';
import { FiDelete } from 'react-icons/fi';
import { MdDelete } from 'react-icons/md';
import { BsPlus } from 'react-icons/bs';

export default function CreateNewSchedulePeriodic() {

    const {
        assessmentTypes,
        newScheduleParams,
        setNewScheduleParams,
        classes,
        subjectList,
        competencyByClassSubjectAssessment,
        setIsCreatingSchedule,
        handleNewScheduleParamsChange,
        handleUpdateDatesForScheduleParams,
        handleSaveSchedulesForPeriodicAssessment,
        confirmSaveSchedule,
        setConfirmSaveSchedule,
        isSavingSchedule,
        newOrfQuestion,
        setNewOrfQuestion
    } = useSchedulePeriodicAssessment()

    useEffect(() => {
        setIsCreatingSchedule(true)
    }, []);
    
    return (
        <PageLayout>
            <EPageTitle title={'Create Periodic Schedule'} />
            <div className="flex justify-center gap-10 mb-3">
                <div className="flex flex-col gap-3 w-full">
                    <Select
                        searchable={assessmentTypes?.length ? true : false}
                        size='md'
                        placeholder="Select"
                        label="Select Assessment"
                        data={assessmentTypes?.map(each => ({
                            value: String(each.id), label: each.name
                        }))}
                        value={newScheduleParams.periodic_Assessment_Id ? String(newScheduleParams.periodic_Assessment_Id) : undefined}
                        onChange={e => {
                            handleNewScheduleParamsChange("periodic_Assessment_Id", Number(e));
                            handleNewScheduleParamsChange("scheduleCompetancy", []);
                        }}
                    /> 

                    <DatePicker
                        size='md'
                        label="Start Date"
                        placeholder='Select date'
                        minDate={new Date()}
                        clearable={false}
                        value={newScheduleParams.startDate ? new Date(newScheduleParams.startDate) : null}
                        onChange={e => {
                            handleUpdateDatesForScheduleParams("endDate", null)
                            handleUpdateDatesForScheduleParams("startDate", moment(e).format("YYYY-MM-DD"))
                        }}
                    />

                    <Select
                        searchable={classes?.length ? true : false}
                        size='md'
                        placeholder="Select"
                        label="Grade"
                        data={classes?.map(each => ({
                            value: String(each.id), label: each.name
                        }))}
                        value={newScheduleParams.class_Id ? String(newScheduleParams.class_Id) : null}
                        onChange={e => {
                            handleNewScheduleParamsChange("class_Id", Number(e));
                            handleNewScheduleParamsChange("subject_Id", 0);
                            handleNewScheduleParamsChange("scheduleCompetancy", []);
                        }}
                    />
                </div>
                <div className="flex flex-col gap-3 w-full">
                    <TextInput
                        size="md"
                        label="No. of Questions"
                        value={newScheduleParams.number_Of_Questions}
                        disabled
                    // onChange={(e) => handleNewScheduleParamsChange("number_Of_Questions", e.target.value)}
                    />

                    <DatePicker
                        size='md'
                        label="Due Date"
                        placeholder='Select date'
                        clearable={false}
                        minDate={newScheduleParams.startDate ? new Date(newScheduleParams.startDate) : new Date(moment().add(1, "day").format())}
                        value={newScheduleParams.endDate ? new Date(newScheduleParams.endDate) : null}
                        onChange={e => handleUpdateDatesForScheduleParams("endDate", moment(e).format("YYYY-MM-DD"))}
                    />

                    <Select
                        searchable={subjectList?.length ? true : false}
                        size='md'
                        placeholder="Select"
                        label="Subject"
                        data={subjectList?.map(each => ({
                            value: String(each.id), label: each.name
                        }))}
                        value={newScheduleParams.subject_Id ? String(newScheduleParams.subject_Id) : null}
                        onChange={e => {
                            handleNewScheduleParamsChange("subject_Id", Number(e));
                            handleNewScheduleParamsChange("scheduleCompetancy", []);
                        }}
                    />
                </div>
            </div>

            {/* COMPETENCIES */}
            <ESectionDivider title={'Competencies'} />
            <>
                <MultiSelect
                    size='md'
                    className='pb-8 mt-8'
                    placeholder="Select Competencies"
                    disabled={newScheduleParams?.subject_Id ? false : true}
                    data={competencyByClassSubjectAssessment?.map(each => ({
                        value: String(each.competancy_Id),
                        label: each.competancy,
                        group: each.assessment_Type
                    }))}
                    value={newScheduleParams.scheduleCompetancy.map(each => String(each.competancy_Id))}
                    onChange={e => {
                        handleNewScheduleParamsChange("scheduleCompetancy", e)
                    }}
                />
                {/* <div>
                        {newScheduleParams.scheduleCompetancy?.map((each, index) => <Badge
                            key={index + "com"}
                            size="lg"
                            radius="xl"
                            color="teal"
                            rightSection={<MdOutlineCancel />}
                        >
                            {each?.competency_name ?? ""}
                        </Badge>)}
                    </div> */}
                {
                    newScheduleParams?.scheduleCompetancy?.length ?
                        <div className='border'>
                            <ETable>
                                <thead>
                                    <tr>
                                        <th>
                                            Competency Name
                                        </th>
                                        <th>
                                            No. of questions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {newScheduleParams?.scheduleCompetancy?.map((each) => (
                                        <tr>
                                            <td>
                                                {each?.competency_name}
                                            </td>
                                            <td>
                                                <TextInput
                                                    placeholder='Enter no. of question'
                                                    value={each?.number_Of_Question ?? null}
                                                    maxLength={2}
                                                    error={isNaN(+each?.number_Of_Question) ? "Value is not a number" : ""}
                                                    onChange={(e) => handleNewScheduleParamsChange("updateCompetencyQuestionNumber", `${each?.competancy_Id},${e?.target.value}`)}
                                                />
                                            </td>
                                        </tr>
                                    ))}
                                    <tr>
                                        <td className="text-center bg-slate-50">
                                            Total No. of Question
                                        </td>
                                        <td className='text-center bg-slate-50 font-semibold text-zinc-800' >{newScheduleParams?.number_Of_Questions ?? 0}</td>
                                    </tr>
                                </tbody>
                            </ETable>
                        </div>
                        :
                        <></>}
            </>

            {/*ORF QUESTIONS */}
            {
                newScheduleParams.subject_Id == 3
                    ?
                    <></>
                    :
                    <>
                        <ESectionDivider title={'ORF Question'} />
                        <div>
                            <Textarea
                                placeholder='Enter question'
                                size='md'
                                minRows={3}
                                value={newOrfQuestion?.orF_Question_Text}
                                onChange={(e) => setNewOrfQuestion(prev => ({ ...prev, orF_Question_Text: e?.target?.value }))}
                            />
                            <div className='flex gap-10 mt-4'>
                                <TextInput
                                    size='md'
                                    className='grow'
                                    placeholder='Enter min word read per minutes'
                                    maxLength={2}
                                    error={isNaN(+newOrfQuestion?.min_Word_Read_Per_Minute) ? "Value is not a number" : ""}
                                    value={newOrfQuestion?.min_Word_Read_Per_Minute}
                                    onChange={(e) => setNewOrfQuestion(prev => ({ ...prev, min_Word_Read_Per_Minute: e?.target?.value }))}
                                />
                                <TextInput
                                    size='md'
                                    className='grow'
                                    error={isNaN(+newOrfQuestion?.max_Seconds_To_Read) ? "Value is not a number" : ""}
                                    maxLength={3}
                                    placeholder='Enter max seconds to read'
                                    value={newOrfQuestion?.max_Seconds_To_Read}
                                    onChange={(e) => setNewOrfQuestion(prev => ({ ...prev, max_Seconds_To_Read: e?.target?.value }))}
                                />
                            </div>
                            <div className='flex justify-end mt-4 mb-4'>
                                <EButton
                                    className="btn-blue"
                                    size="md"
                                    disabled={newOrfQuestion?.max_Seconds_To_Read && newOrfQuestion?.min_Word_Read_Per_Minute && newOrfQuestion?.orF_Question_Text ? false : true}
                                    onClick={() => {
                                        if (newScheduleParams?.scheduleORFQuestion?.length) {
                                            setNewScheduleParams(prev =>
                                            ({
                                                ...prev,
                                                scheduleORFQuestion:
                                                    [...prev?.scheduleORFQuestion, newOrfQuestion]
                                            }))
                                        }
                                        else {
                                            setNewScheduleParams(prev => ({ ...prev, scheduleORFQuestion: [newOrfQuestion] }))
                                        }
                                        setNewOrfQuestion({
                                            orF_Question_Text: "",
                                            max_Seconds_To_Read: "",
                                            min_Word_Read_Per_Minute: ""
                                        })
                                    }}
                                ><BsPlus size={22} /> Add ORF</EButton>
                            </div>

                            {
                                newScheduleParams?.scheduleORFQuestion?.length ?
                                    <div className='border p-1'>
                                        <ETable>
                                            <thead>
                                                <tr>
                                                    <th>Question</th>
                                                    <th>Min word read per minutes</th>
                                                    <th>Max seconds to read</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    newScheduleParams?.scheduleORFQuestion?.map((item, index) => (
                                                        <tr>
                                                            <td>{item?.orF_Question_Text}</td>
                                                            <td>{item?.min_Word_Read_Per_Minute}</td>
                                                            <td>{item?.max_Seconds_To_Read}</td>
                                                            <td> {
                                                                <p
                                                                    className='cursor-pointer text-danger'
                                                                    onClick={() => {
                                                                        let allQuestions = newScheduleParams?.scheduleORFQuestion
                                                                        allQuestions.splice(index, 1)
                                                                        setNewScheduleParams(
                                                                            prev => ({
                                                                                ...prev, scheduleORFQuestion: allQuestions
                                                                            })
                                                                        )
                                                                    }}
                                                                >
                                                                    <MdDelete size={22} />
                                                                </p>
                                                            }
                                                            </td>
                                                        </tr>
                                                    ))
                                                }
                                            </tbody>
                                        </ETable>
                                    </div>
                                    :
                                    <></>
                            }
                        </div>
                    </>
            }

            {/* footer */}
            <div className="mt-10 flex w-full justify-between">
                <Checkbox
                    label={"Are you sure you want to save?"}
                    checked={confirmSaveSchedule}
                    onClick={() => setConfirmSaveSchedule(!confirmSaveSchedule)}
                />
                <EButton
                    className="btn-blue"
                    loading={isSavingSchedule}
                    disabled={!confirmSaveSchedule}
                    onClick={() => handleSaveSchedulesForPeriodicAssessment()}
                >
                    Save
                </EButton>
            </div>
        </PageLayout>
    )
}
