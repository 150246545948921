import {
    HiOutlineDocumentReport
} from "react-icons/hi";
import {
    HiHome, HiQuestionMarkCircle
} from "react-icons/hi2";
import {
    GiTeacher, GiFulguroPunch, GiTargeting, GiBookPile
} from "react-icons/gi";
import {
    AiOutlineSchedule, AiFillSchedule, AiFillWallet
} from "react-icons/ai";
import { IoSchoolOutline } from "react-icons/io5";
import { BsChatDots, BsList } from "react-icons/bs";
import { FaCalendarDay } from "react-icons/fa";
import { TbDetails, TbReportAnalytics } from "react-icons/tb";
import { MdDetails, MdNotificationAdd, MdOutlineModelTraining } from "react-icons/md";
import { BsCardChecklist } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { FaCloudDownloadAlt } from "react-icons/fa";

import Ticket from "../Pages/Tickets/Ticket";
import Grievance from "../Pages/Grievance/Grievance";
import QuestionBank from "../Pages/QuestionBank/Pages/QuestionBank";
import SchedulePeriodicAssessment from "../Pages/Assessments/PeriodicAssessment/SchedulePeriodicAssessment";
import TeacherAllocationBySchoolIndex from "../Pages/TeacherAllocationBySchool/TeacherAllocationBySchoolIndex";
import DashboardReports from "../Pages/Reports/Overview/VisitCompliance/DashboardReports";
import ClassroomObservation from "../Pages/Reports/Overview/ClassroomObservation/ClassroomObservation";
import SchoolWiseClassroomObservation from "../Pages/Reports/Overview/SchoolWiseClassroomObservation/SchoolWiseClassroomObservation";
import Thresholds from "../Pages/Reports/Overview/Threshold/Thresholds";
import QuestionBankIndex from "../Pages/QuestionBank/Pages/QuestionBankIndex";
import TeacherTrainingSlot from "../Pages/TeacherTraining/StateAdminPages/TeacherTrainingSlot";
import EditTrainingSlot from "../Pages/TeacherTraining/StateAdminPages/pages/EditTrainingSlot";
import TrainingSlotIndex from "../Pages/TeacherTraining/BlockAdminPages/Pages/SlotList/TrainingSlotIndex";
import CreateNewSchedule from "../Pages/TeacherTraining/BlockAdminPages/Pages/Schedule/CreateNewSchedule";
import EditSchedule from "../Pages/TeacherTraining/BlockAdminPages/Pages/Schedule/EditSchedule";
import ScheduleList from "../Pages/TeacherTraining/BlockAdminPages/Pages/Schedule/ScheduleList";
import SpotAssessment from "../Pages/Reports/StudentLearning/SpotAssessment/SpotAssessment";
import PeriodicAssessment from "../Pages/Reports/StudentLearning/PeriodicAssessment/PeriodicAssessment";
import MarkAttendance from "../Pages/TeacherTraining/BlockAdminPages/Pages/MarkAttendance/MarkAttendance";
import SlotQuestionBank from "../Pages/TeacherTraining/StateAdminPages/pages/SlotQuestionBank";
import SlotQuestionList from "../Pages/TeacherTraining/StateAdminPages/pages/SlotQuestionList";
import TargetIndex from "../Pages/Targets/TargetIndex";
import CreateNewTarget from "../Pages/Targets/CreateNewTarget";
import Dashboard from "../Pages/Dashboard/Dashboard";
import { FcSurvey, FcTodoList } from "react-icons/fc";
import CreateSchedule from "../Pages/Assessments/PeriodicAssessment/Components/CreateSchedule";
import CreateNewSchedulePeriodic from "../Pages/Assessments/PeriodicAssessment/pages/CreateNewSchedulePeriodic";
import SurveyList from "../Pages/Survey/SurveyList";
import CreateNewSurvey from "../Pages/Survey/pages/CreateNewSurvey";
import SurveyDetails from "../Pages/Survey/pages/SurveyDetails";
import DetailsKpiIndex from "../Pages/DetailsKPI/DetailsKpiIndex";
import { BiDetail } from "react-icons/bi";
import EditSchedulePeriodic from "../Pages/Assessments/PeriodicAssessment/pages/EditSchedulePeriodic";
import MISDataSync from "../Pages/MIS_Data_Sync/Pages/MISDataSync";
import Notification from "../Pages/Notification/Notification";
import NewNotification from "../Pages/Notification/NewNotification";
import TeacherTrainingReport from "../Pages/Reports/Overview/TeacherTrainingReport/TeacherTrainingReport";
import WorkingDaysIndex from "../Pages/Attendance/WorkingDays/WorkingDaysIndex";
import Scorecard from "../Pages/Scorecard/Scorecard";
import SpotAssessementQuestion from "../Pages/SpotQuestionBank/Questions/SpotAssessementQuestion";
import SpotAssessementAddQuestion from "../Pages/SpotQuestionBank/Questions/SpotAssessementAddQuestion";
import SpotAssessementGroup from "../Pages/SpotQuestionBank/QuestionGroup/SpotAssessementGroup";
import SpotAssessementAddGroup from "../Pages/SpotQuestionBank/QuestionGroup/SpotAssessementAddGroup";
import OrfQuestion from "../Pages/SpotQuestionBank/OrfQuestion/OrfQuestion";
import orfQuestionListing from "../Pages/SpotQuestionBank/OrfQuestion/orfQuestionListing";
import DownloadAssessement from "../Pages/DownloadAssessement/DownloadAssessement";
import UserManagement from "../Pages/UserManagement/UserManagement";
import MentorMonitorClassroomObservation from "../Pages/Reports/Overview/MentorMonitorClassroomObservation/MentorMonitorClassroomObservation";

export const ROLES = {
    DEPARTMENT: "State Administrator", // 6
    DISTRICT: "District Administrator", // 7
    HELPDESK: "HelpDesk User", // 9
    SCHOOL: "School Administrator", // 1
    BLOCK: "Block Administrator" // 8
};
export interface IRoute {
    path: string;
    label: string;
    element: any;
    icon?: any;
    isSubmenu?: boolean;
    submenu?: IRoute[];
    rolesAllowed?: string[];
}

export const DEPARTMENT_PATH = {
    questionBank: "/upload-question-bank",
};

export const ROUTES: {
  DEPARTMENT_ROUTES: IRoute[];
} = {
  DEPARTMENT_ROUTES: [
    {
      path: "/",
      label: "Home",
      // element: Dashboard,
      element: Dashboard,
      icon: HiHome,
      rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
    },
    {
      path: "/", // "/teacher/allocation",
      label: "Teacher Allocation",
      element: TeacherAllocationBySchoolIndex,
      icon: GiTeacher,
      rolesAllowed: [ROLES.SCHOOL],
    },
    {
      path: "/academic-mentoring",
      label: "Academic Mentoring",
      element: DetailsKpiIndex,
      icon: BiDetail,
      rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
    },
    {
      path: "/scorecard",
      label: "Scorecard",
      element: Scorecard,
      icon: BsCardChecklist,
      rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
    },
    {
      path: "/user",
      label: "User Management",
      element: UserManagement,
      icon: FaUser,
      rolesAllowed: [ROLES.DEPARTMENT],
    },
    {
      path: "",
      label: "Student Learning",
      element: "",
      icon: TbReportAnalytics,
      rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
      isSubmenu: true,
      submenu: [
        {
          path: "/student-learning/spot-assessment",
          label: "Spot Assessment",
          element: SpotAssessment,
          icon: IoSchoolOutline,
          rolesAllowed: [
            ROLES.DEPARTMENT,
            ROLES.DISTRICT,
            ROLES.BLOCK,
            ROLES.SCHOOL,
          ],
        },
        {
          path: "/student-learning/periodic-assessment",
          label: "Periodic Assessment",
          element: PeriodicAssessment,
          icon: IoSchoolOutline,
          rolesAllowed: [
            ROLES.DEPARTMENT,
            ROLES.DISTRICT,
            ROLES.BLOCK,
            ROLES.SCHOOL,
          ],
        },
      ],
    },
    {
      path: "",
      label: "Detailed KPIs",
      element: "",
      icon: GiBookPile,
      isSubmenu: true,
      rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
      submenu: [
        /* REPORTS */
        {
          path: "/teacher-training-report",
          label: "Teacher Training Report",
          element: TeacherTrainingReport,
          icon: TbReportAnalytics,
          rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
        },
        {
          path: "",
          label: "Visit Reports",
          element: "",
          icon: TbReportAnalytics,
          rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
          isSubmenu: true,
          submenu: [
            {
              path: "/reports/visit-compliance",
              label: "Visit Compliance",
              element: DashboardReports,
              icon: IoSchoolOutline,
              rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
            },
            {
              path: "/reports/classroom-observation",
              label: "Classroom Observation",
              element: ClassroomObservation,
              icon: IoSchoolOutline,
              rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
            },
            {
              path: "/reports/School-classroom-observation",
              label: "School Observation",
              element: SchoolWiseClassroomObservation,
              icon: IoSchoolOutline,
              rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
            },
            {
              path: "/reports/thresholds",
              label: "Thresholds",
              element: Thresholds,
              icon: IoSchoolOutline,
              rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
            },
            {
              path: "/reports/mentor-monitor-classroom-observation",
              label: "Monitor Classroom Observation",
              element: MentorMonitorClassroomObservation,
              icon: IoSchoolOutline,
              rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
            },
          ],
        },
      ],
    },
    {
      path: "",
      label: "Other Features",
      element: "",
      icon: AiFillWallet,
      isSubmenu: true,
      rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT, ROLES.BLOCK],
      submenu: [
        /* Targets */
        {
          path: "/target",
          label: "Target",
          element: TargetIndex,
          icon: GiTargeting,
          rolesAllowed: [ROLES.DEPARTMENT],
        },

        /* Survey */
        {
          path: "/survey",
          label: "Survey",
          element: SurveyList,
          icon: FcSurvey,
          rolesAllowed: [ROLES.DEPARTMENT],
        },
        {
          path: "/notification",
          label: "Notification",
          element: Notification,
          icon: MdNotificationAdd,
          rolesAllowed: [ROLES.DEPARTMENT],
        },

        {
          path: "/mentor-monitor-image",
          label: "Mentor/Monitor Image",
          element: DownloadAssessement,
          icon: FaCloudDownloadAlt,
          rolesAllowed: [ROLES.DEPARTMENT, ROLES.DISTRICT],
        },

        /* Help Desk */
        {
          path: "/helpdesk",
          label: "Helpdesk",
          element: Ticket,
          icon: GiFulguroPunch,
          rolesAllowed: [
            ROLES.DEPARTMENT,
            ROLES.DISTRICT,
            ROLES.BLOCK,
            ROLES.SCHOOL,
          ],
        },
        /* Periodic assessment */
        {
          path: "",
          label: "Periodic Assessment",
          element: "",
          icon: TbReportAnalytics,
          rolesAllowed: [ROLES.DEPARTMENT],
          isSubmenu: true,
          submenu: [
            {
              path: "/schedule-periodic-assessment",
              label: "Schedule Periodic Assessment",
              element: SchedulePeriodicAssessment,
              icon: AiOutlineSchedule,
              rolesAllowed: [ROLES.DEPARTMENT],
            },
            {
              path: "/question-bank",
              label: "Question Bank",
              element: QuestionBankIndex,
              icon: FcTodoList,
              rolesAllowed: [ROLES.DEPARTMENT],
            },
            {
              path: "/upload-question-bank",
              label: "Upload Question Bank",
              element: QuestionBank,
              icon: HiQuestionMarkCircle,
              rolesAllowed: [ROLES.DEPARTMENT],
            },
          ],
        },

        /* Spot assessment */
        {
          path: "",
          label: "Spot Assessment",
          element: "",
          icon: TbReportAnalytics,
          rolesAllowed: [ROLES.DEPARTMENT],
          isSubmenu: true,
          submenu: [
            {
              path: "/spot-question-Group",
              label: "Question Group",
              element: SpotAssessementGroup,
              icon: FcTodoList,
              rolesAllowed: [ROLES.DEPARTMENT],
            },
            {
              path: "/upload-spot-question-Group",
              label: "Upload Question Group",
              element: SpotAssessementAddGroup,
              icon: HiQuestionMarkCircle,
              rolesAllowed: [ROLES.DEPARTMENT],
            },
            {
              path: "/spot-question-bank",
              label: "Question Bank",
              element: SpotAssessementQuestion,
              icon: FcTodoList,
              rolesAllowed: [ROLES.DEPARTMENT],
            },
            {
              path: "/upload-spot-question-bank",
              label: "Upload Question Bank",
              element: SpotAssessementAddQuestion,
              icon: HiQuestionMarkCircle,
              rolesAllowed: [ROLES.DEPARTMENT],
            },
            {
              path: "/spot-orf-question",
              label: "Orf Questions",
              element: orfQuestionListing,
              icon: FcTodoList,
              rolesAllowed: [ROLES.DEPARTMENT],
            },
            {
              path: "/upload-spot-orf-question",
              label: "Upload Orf Question",
              element: OrfQuestion,
              icon: HiQuestionMarkCircle,
              rolesAllowed: [ROLES.DEPARTMENT],
            },
          ],
        },

        /* State Slot for Teacher Training */
        {
          path: "",
          label: "Teacher Training Slot",
          element: "",
          icon: GiTeacher,
          rolesAllowed: [ROLES.DEPARTMENT],
          isSubmenu: true,
          submenu: [
            {
              path: "/state/training-slot",
              label: "Training Slot",
              element: TeacherTrainingSlot,
              icon: MdOutlineModelTraining,
              rolesAllowed: [ROLES.DEPARTMENT],
            },
            {
              path: "/state/training-slot/question-bank",
              label: "Slot Question Bank",
              element: SlotQuestionList,
              icon: HiQuestionMarkCircle,
              rolesAllowed: [ROLES.DEPARTMENT],
            },
          ],
        },

        /* Block Schedule Training */
        {
          path: "",
          label: "Teacher Training Schedule",
          element: "",
          icon: GiTeacher,
          rolesAllowed: [ROLES.BLOCK],
          isSubmenu: true,
          submenu: [
            {
              path: "/block/schedule-list",
              label: "Schedule List",
              element: ScheduleList,
              icon: AiFillSchedule,
              rolesAllowed: [ROLES.BLOCK],
            },
            {
              path: "/block/training-slot",
              label: "Training Slots",
              element: TrainingSlotIndex,
              icon: MdOutlineModelTraining,
              rolesAllowed: [ROLES.BLOCK],
            },
          ],
        },

        // MIS Data Sync
        {
          path: "/mis-data-sync",
          label: "MIS Data Sync",
          element: MISDataSync,
          icon: HiQuestionMarkCircle,
          rolesAllowed: [ROLES.DEPARTMENT, ROLES.SCHOOL],
        },
        {
          path: "/state/working-days",
          label: "Working Days",
          element: WorkingDaysIndex,
          icon: FaCalendarDay,
          rolesAllowed: [ROLES.DEPARTMENT],
        },
      ],
    },

    /* Grievance portal*/
    {
      path: "/",
      label: "Grievance",
      element: Grievance,
      icon: BsChatDots,
      rolesAllowed: [ROLES.HELPDESK],
    },

    /* TEACHER ALLOCATION FOR SCHOOL LOGIN */
    // {
    //     path: "/", // "/teacher/allocation",
    //     label: "Teacher Allocation",
    //     element: TeacherAllocationBySchoolIndex,
    //     icon: GiTeacher,
    //     rolesAllowed: [ROLES.SCHOOL]
    // },
    {
      path: "/new-schedule-periodic",
      label: "",
      element: CreateNewSchedulePeriodic,
      rolesAllowed: [ROLES.DEPARTMENT],
    },
    {
      path: "/edit-schedule-periodic/:id",
      label: "",
      element: EditSchedulePeriodic,
      rolesAllowed: [ROLES.DEPARTMENT],
    },
    {
      path: "/state/training-slot/add-question",
      label: "",
      element: SlotQuestionBank,
      rolesAllowed: [ROLES.DEPARTMENT],
    },
    {
      path: "/edit-slot/:id",
      label: "",
      element: EditTrainingSlot,
      rolesAllowed: [ROLES.DEPARTMENT],
    },
    {
      path: "/create-schedule/:slotID",
      label: "",
      element: CreateNewSchedule,
      rolesAllowed: [ROLES.BLOCK],
    },
    {
      path: "/edit-schedule/:slotId/:scheduleId",
      label: "",
      element: EditSchedule,
      rolesAllowed: [ROLES.BLOCK],
    },
    {
      path: "/mark-attendance/:id",
      label: "",
      element: MarkAttendance,
      rolesAllowed: [ROLES.BLOCK],
    },
    {
      path: "/new-survey",
      label: "",
      element: CreateNewSurvey,
      rolesAllowed: [ROLES.DEPARTMENT],
    },
    {
      path: "/survey-details/:surveyId",
      label: "",
      element: SurveyDetails,
      rolesAllowed: [ROLES.DEPARTMENT],
    },
    {
      path: "/new-target",
      label: "",
      element: CreateNewTarget,
      rolesAllowed: [ROLES.DEPARTMENT],
    },
    {
      path: "/new-notification",
      label: "",
      element: NewNotification,
      rolesAllowed: [ROLES.DEPARTMENT],
    },
  ],
};