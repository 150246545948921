import React, { useContext } from 'react'
import useMentorWiseVisitCompliance from '../Hooks/useMentorWiseVisitCompliance';
import { Select, Skeleton } from '@mantine/core';
import { EButton, EInfoPanel, EVerticalFormSection } from '../../../../../libs/ui';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { AppContext } from '../../../../../AppContext';
import { ROLES } from '../../../../../Routes/routes';

export default function MentorWiseVisitCompliance() {
     
    const {
        user,
        role
    } =useContext(AppContext)
    
    const {
      isLoading,
      handleSelectMonth,
      mentorWiseVisitComplianceReport,
      districtWiseComplianceCSV,
      fetchDistrictWiseMentorVisitCompliance,
      selectedBlock,
      selectedDistrict,
      districts,
      blocks,
      setSelectedBlock,
      setSelectedDistrict,
      selectedMonth,
      setSelectedMonth,
      handelClearFilter,
      selectedSession,
      setSelectedSession,
      handleChangeUserMonitor,
      userMonitorValue,
      userManageRoles,
      userManageDesignation,
    } = useMentorWiseVisitCompliance();
    return (
      <div>
        {/* FILTERS
        <div className="p-3 rounded-md bg-slate-200">
            <h4>Filters</h4>
            <div className="flex justify-between items-center">
                <Select
                    label="Select month"
                    placeholder="Select month"
                    data={
                        [
                            { label: "January", value: "01" },
                            { label: "February", value: "02" },
                            { label: "March", value: "03" },
                            { label: "April", value: "04" },
                            { label: "May", value: "05" },
                            { label: "June", value: "06" },
                            { label: "July", value: "07" },
                            { label: "August", value: "08" },
                            { label: "September", value: "09" },
                            { label: "October", value: "10" },
                            { label: "November", value: "11" },
                            { label: "December", value: "12" }
                        ]
                    }
                    value={selectedMonth}
                    onChange={e => handleSelectMonth(String(e))}
                />

                <EButton
                    className="btn-blue"
                    disabled={!selectedMonth}
                    loading={isLoading}
                    onClick={fetchDistrictWiseMentorVisitCompliance}
                >Search</EButton>
            </div>
        </div> */}

        {/* FILTERS */}
        <div className="filter-container">
          <div className="flex justify-between w-full ">
            <div className="flex gap-4 flex-wrap">
              <Select
                label="Select Session"
                placeholder="Select month"
                data={[
                  { label: "2023-2024", value: "2024" },
                  { label: "2024-2025", value: "2025" },
                ]}
                value={selectedSession}
                onChange={(e) => setSelectedSession(String(e))}
              />

              {/* <Select
                label="Select month"
                placeholder="Select month"
                data={[
                  { label: "January", value: "01" },
                  { label: "February", value: "02" },
                  { label: "March", value: "03" },
                  { label: "April", value: "04" },
                  { label: "May", value: "05" },
                  { label: "June", value: "06" },
                  { label: "July", value: "07" },
                  { label: "August", value: "08" },
                  { label: "September", value: "09" },
                  { label: "October", value: "10" },
                  { label: "November", value: "11" },
                  { label: "December", value: "12" },
                ]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(String(e))}
              /> */}

              <Select
                label="Select month"
                placeholder="Select month"
                data={[
                  {
                    label: "April",
                    value: "04",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 4
                        ? true
                        : false,
                  },
                  {
                    label: "May",
                    value: "05",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 5
                        ? true
                        : false,
                  },
                  {
                    label: "June",
                    value: "06",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 6
                        ? true
                        : false,
                  },
                  {
                    label: "July",
                    value: "07",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 7
                        ? true
                        : false,
                  },
                  {
                    label: "August",
                    value: "08",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 8
                        ? true
                        : false,
                  },
                  {
                    label: "September",
                    value: "09",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 9
                        ? true
                        : false,
                  },
                  {
                    label: "October",
                    value: "10",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 10
                        ? true
                        : false,
                  },
                  {
                    label: "November",
                    value: "11",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 11
                        ? true
                        : false,
                  },
                  {
                    label: "December",
                    value: "12",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 12
                        ? true
                        : false,
                  },
                  {
                    label: "January",
                    value: "01",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "February",
                    value: "02",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "March",
                    value: "03",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                ]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(String(e))}
              />

              <Select
                label="Select District"
                placeholder="Select district"
                clearable
                disabled={
                  role?.includes(ROLES.BLOCK) || role?.includes(ROLES.DISTRICT)
                    ? true
                    : false
                }
                data={districts?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedDistrict ?? null}
                onChange={(e: any) => {
                  setSelectedBlock(undefined);
                  setSelectedDistrict(e);
                }}
              />
              <Select
                label="Select Block"
                placeholder="Select block"
                clearable
                disabled={
                  !selectedDistrict || role?.includes(ROLES.BLOCK)
                    ? true
                    : false
                }
                data={blocks?.map((each) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={selectedBlock ?? null}
                onChange={(e: any) => setSelectedBlock(e)}
              />

              <Select
                label="Role"
                placeholder="Select Role"
                // className=" w-1/5 grow"
                value={userMonitorValue?.role}
                data={userManageRoles?.map((each: any) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                onChange={(e: any) => {
                  handleChangeUserMonitor({ name: "role", value: e });
                  handleChangeUserMonitor({
                    name: "designation",
                    value: null,
                  });
                }}
              />

              <Select
                label="Designation"
                placeholder="Select Designation"
                data={userManageDesignation?.map((each: any) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={userMonitorValue?.designation}
                onChange={(e: any) =>
                  handleChangeUserMonitor({
                    name: "designation",
                    value: e,
                  })
                }
              />
            </div>
            <div className="flex gap-3 pt-6">
              <EButton
                className="btn-light"
                disabled={
                  !selectedDistrict ||
                  role?.includes(ROLES.BLOCK) ||
                  role?.includes(ROLES.DISTRICT)
                    ? true
                    : false
                }
                onClick={handelClearFilter}
              >
                Clear
              </EButton>
              <EButton
                className="btn-blue"
                disabled={!selectedMonth}
                loading={isLoading}
                onClick={() => {
                  fetchDistrictWiseMentorVisitCompliance();
                }}
              >
                Search
              </EButton>
            </div>
          </div>
        </div>

        {/* DATA */}
        <div className="mt-5">
          {isLoading ? (
            <Skeleton radius={"md"} height={250} />
          ) : mentorWiseVisitComplianceReport.length ? (
            <EVerticalFormSection
              title={`Report on Mentor Wise Visit Compliance for ${moment(
                selectedMonth
              ).format("MMM")}-${moment().format("YYYY")}`}
              rightPanel={<></>}
            >
              <div>
                <EInfoPanel title=" Your report is ready to download, Please click on Download File to download report">
                  <CSVLink
                    className="text-blue-600 text-sm hover:underline"
                    data={districtWiseComplianceCSV?.content ?? []}
                    headers={districtWiseComplianceCSV?.headers ?? []}
                    filename={`Report_on_Mentor_Visit_Compliance_for_${moment(
                      selectedMonth
                    ).format("MMM")}-${moment().format("YYYY")}.csv`}
                  >
                    Download File
                  </CSVLink>
                </EInfoPanel>
              </div>
            </EVerticalFormSection>
          ) : (
            <EInfoPanel title="Please select a month and press search to generate report" />
          )}
        </div>
      </div>
    );
}
