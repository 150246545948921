import React, { useEffect, useState } from 'react'
import { calculateCurrentMonth, calculateYearForDashboard, showToast } from '../../../../../utils/utility';
import moment from 'moment';
import { useUserManagementFetch } from '../../../../../libs/data-access/src/UserManagement/useUserManageFetch';
import { useMentorReports } from '../../../../../libs/data-access';

const useMentorMonitorClassroomObservation = () => {

  const { fetchMentorReport } = useMentorReports();

const { fetchUserManagementData } = useUserManagementFetch();

        const [selectedMonth, setSelectedMonth] = useState<string | undefined>(
          calculateCurrentMonth()
        );
        const [selectedSession, setSelectedSession] = useState<
          string | undefined
        >(
          parseInt(moment().format("MM"), 10) > 4
            ? moment().add(1, "year").format("YYYY")
            : moment().format("YYYY")
        );

 const [userMonitorValue, setUserMonitorValue] = useState<any>({
  //  role: undefined,
   role: "11",
   designation: undefined,
   subject: "2",
 });

  const [isLoading, setIsLoading] = useState<boolean>(false);

 const [userManageRoles, setuserManageRoles] = useState([]);
 const [userManageDesignation, setuserManageDesignation] = useState([]);

     const [
       mentorWiseClassroomObservationReport,
       setMentorWiseClassroomObservationReport,
     ] = useState<any[]>([]);
     // data for CSV download
     const [mentortWiseClassroomObCSV, setMentortWiseClassroomObCSV] =
       useState<{ headers: any[]; content: any[] }>();

    const handleChangeUserMonitor = ({
      name,
      value,
    }: {
      name: string;
      value: any;
    }) => {
      setUserMonitorValue((prev: any) => {
        return {
          ...prev,
          [name]: value,
        };
      });
    };


          const manageGetRoles = async () => {
            setuserManageRoles([]);
            let response = await fetchUserManagementData(`GetRoleMaster`);
            if (response?.isSuccess) {
              const data = response.data.map((e: any) => ({
                id: e?.role_Id,
                name: e?.role_Name,
              }));
              data && setuserManageRoles(data);
            } else {
              console.log("Error while getting roles list");
            }
          };

          const manageGetDesignationByRoles = async (roleId: any) => {
            setuserManageDesignation([]);
            let response = await fetchUserManagementData(
              `GetDesignationByRole?RoleId=${roleId}`
            );
            if (response?.isSuccess) {
              const data = response.data.map((e: any) => ({
                id: e?.designation_Id,
                name: e?.designation,
              }));
              data && setuserManageDesignation(data);
            } else {
              console.log("Error while getting roles list");
            }
          };

          const handelClearFilter = () => {
            setUserMonitorValue({
              role: undefined,
              designation: undefined,
              subject: undefined,
            });
          };

          const formatClassroomMathObservationReport = (
            visitCompliance: any[]
          ) => {
            const headers = [
              { label: "District", key: "district" },
              {
                label:
                  "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका के सभी चरणों का उपयोग किया जा रहा है ",
                key: "question_1_option_1",
              },
              {
                label:
                  "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका के कुछ चरणों का उपयोग किया जा रहा है ",
                key: "question_1_option_2",
              },
              {
                label:
                  "Question 1. कक्षा में शिक्षक द्वारा शिक्षक संदर्शिका के उपयोग की स्थिति -> Option : कक्षा योजना हेतु शिक्षक संदर्शिका का उपयोग  किया जा रहा है  ",
                key: "question_1_option_3",
              },
              {
                label:
                  "Question 2. क्या गतिविधि को शिक्षक संदर्शिका में वर्णित शिक्षण योजना के चरणों के अनुसार कराया गया है? -> Option हाँ,  गतिविधि को शिक्षण योजना के चरणों के अनुसार कराया गया  ",
                key: "question_2_option_1",
              },
              {
                label:
                  "Question 2. क्या गतिविधि को शिक्षक संदर्शिका में वर्णित शिक्षण योजना के चरणों के अनुसार कराया गया है? -> Option नहीं, गतिविधि को शिक्षण योजना के चरणों के अनुसार  नहीं कराया गया ",
                key: "question_2_option_2",
              },
              {
                label:
                  "Question 3. कक्षा कक्ष में प्रिंट सामग्री का प्रदर्शन -> Option :  कक्षा मे प्रदर्शित प्रिंट व गतिबिधि के लिए सामग्री व्यवस्थित और बच्चों की पहुँच में थी। ",
                key: "question_3_option_1",
              },
              {
                label:
                  "Question 3. कक्षा कक्ष में प्रिंट सामग्री का प्रदर्शन -> Option :  कक्षा मे प्रदर्शित प्रिंट व गतिबिधि के लिए सामग्री लगी थी पर बच्चों की पहुँच मेंनहीं थी।  ",
                key: "question_3_option_2",
              },
              {
                label:
                  "Question 3. कक्षा कक्ष में प्रिंट सामग्री का प्रदर्शन -> Option :  कक्षा-कक्ष में कोई प्रिंट सामग्री प्रदर्शित नहीं थी।",
                key: "question_3_option_3",
              },
            ];

            const data = visitCompliance?.map((each) =>
              Object.assign({
                district: each.district_Name || "",
                question_1_option_1: each?.q1_Option1 || "",
                question_1_option_2: each?.q1_Option2 || "",
                question_1_option_3: each?.q1_Option3 || "",
                question_2_option_1: each?.q2_Option1 || "",
                question_2_option_2: each?.q2_Option2 || "",
                question_3_option_1: each?.q3_Option1 || "",
                question_3_option_2: each?.q3_Option2 || "",
                question_3_option_3: each?.q3_Option3 || "",
              })
            );

            setMentortWiseClassroomObCSV({
              headers: headers,
              content: data,
            });
          };


              const formatClassroomHindiObservationReport = (
                visitCompliance: any[]
              ) => {

                // orderColumn to serialize column implement it in headers else part and change name in label key 
                // const ordereColumn = [
                //   "date",
                //   "district_Name",
                //   "block_Name",
                //   "cluster_School_Name",
                //   "unique_Code",
                //   "school_Name",
                //   "school_Code",
                //   "teacher_Name",
                //   "teacher_Code",
                //   "class_Name",
                //   "section_Name",
                //   "subject_Name",
                //   "time_Taken",
                // ];
                const getQuestionHeader = (questionId:any, questionJson:any) => {
                  const question = questionJson?.find(
                    (q:any) => q.Observation_Question_Id === questionId
                  );
                  return question ? question.Observation_Question : questionId;
                };

                // Generate headers dynamically
                const headers = Object?.keys(visitCompliance[0])?.flatMap(
                  (key) => {
                    if (key === "questionJson") return []; // Skip questionJson itself
                    if (key.startsWith("question")) {
                      // Dynamically get header for question fields
                      const questionJson = JSON.parse(visitCompliance[0].questionJson); // Assuming all rows have the same questionJson structure
                      return {
                        label: getQuestionHeader(key, questionJson),
                        key,
                      };
                    }
                    
                    return { label: key, key };
                  }
                );

                // Prepare data for export
                const data = visitCompliance?.map((row: any) => {
                  const result = { ...row };
                    if (result.date) {
                      result.date = moment(result.date).format(
                        "DD/MM/YYYY, hh:mm A"
                      ); 
                    }
                  delete result.questionJson; 
                  return result;
                });

                setMentortWiseClassroomObCSV({
                  headers: headers,
                  content: data,
                });
              };

          const fetchSubjectWiseMentorClassroomObservation = async() => {
            if (userMonitorValue?.role?.length > 0) {
              setIsLoading(true);
              const response = await fetchMentorReport(
                `GetClassroomObservationReport_Hindi_Math_Monitor/${`${
                  selectedMonth
                    ? calculateYearForDashboard(
                        +selectedMonth,
                        Number(selectedSession)
                      )
                    : moment().format("YYYY")
                }-${selectedMonth}-01`}/${userMonitorValue?.subject}`
                // /${
                //   userMonitorValue?.role ?? 0
                // }/${
                //   userMonitorValue?.designation?.length > 0
                //     ? userMonitorValue?.designation
                //     : 0}
              );
              if (response.isSuccess) {
                setMentorWiseClassroomObservationReport(response.data);
                formatClassroomHindiObservationReport(response.data);
                // if (userMonitorValue?.subject == "2") {
                //   formatClassroomHindiObservationReport(response.data);
                // } else {
                //   formatClassroomMathObservationReport(response.data);
                // }
              } else {
                showToast({
                  type: "error",
                  title: "Error while fetching report",
                  message: response.message || "Something went wrong!",
                });
              }
              setIsLoading(false);
            } else {
              showToast({
                type: "warning",
                message: "Please provide role",
              });
            }

          };

          useEffect(() => {
            manageGetRoles();
          }, []);

          useEffect(() => {
            if (userMonitorValue?.role?.length > 0) {
              manageGetDesignationByRoles(userMonitorValue?.role);
            }
          }, [userMonitorValue?.role]);

  return {
    selectedMonth,
    setSelectedMonth,
    selectedSession,
    setSelectedSession,
    userMonitorValue,
    setUserMonitorValue,
    handleChangeUserMonitor,
    userManageRoles,
    userManageDesignation,
    handelClearFilter,
    isLoading,
    fetchSubjectWiseMentorClassroomObservation,
    mentorWiseClassroomObservationReport,
    mentortWiseClassroomObCSV,
  };
}

export default useMentorMonitorClassroomObservation