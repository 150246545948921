import React, { useEffect, useState } from 'react'
import useMarkAttendance from '../hooks/useMarkAttendance'
import { Grid, Skeleton, Switch } from '@mantine/core'
import { FaUser } from 'react-icons/fa'
import { BsCheckCircle, BsXCircle } from 'react-icons/bs'
import { EButton, EEmpty } from '../../../../../../libs/ui'
import { Console } from 'console'
import { ILastAttendanceDetails, ITeacherList, ITeacherMarkAttendanceList } from '../../../../../../libs/data-access/types'

type IProps = {
  isLoading: boolean;
  isBtnLoading: boolean;
  handleEditAttendanceChange: (index: number, event: any) => void;
  lastAttendanceDetails: ITeacherList[];
  teacherMarkAttendanceList: any[];
  handleSubmitLastAttendance: () => void;
};
export default function EditLastAttendance({
  isLoading,
  isBtnLoading,
  lastAttendanceDetails,
  handleEditAttendanceChange,
  handleSubmitLastAttendance,
  teacherMarkAttendanceList,
}: IProps) {
const [noAttendanceMArked, setNoAttendanceMArked] = useState(true);
    const [eligibleEditSlot, setEligibleEditSlot] = useState(false);
    const [editSlotTime, setEditSlotTime] = useState(
      "You are editing the attendance of the last slot: 8:30 am - 10:00 am"
    );

  // Define the check and edit function
  function checkAndEditAttendance(attendanceData: any[]) {
    const targetDate = new Date(); // Today's date
    const currentHour = targetDate.getHours();
    const currentMinute = targetDate.getMinutes();
    const currentTime = currentHour + currentMinute / 60;

    // Define time slots with start and end times (in 24-hour format)
    const timeSlots = [
      { start: 8.5, end: 10, label: "8:30 am - 10:00 am" },
      { start: 13.5, end: 14.5, label: "1:30 pm - 2:30 pm" },
      { start: 16, end: 17, label: "4:00 pm - 5:00 pm" },
    ];

    // Get the last attendance entry for today
    const todayAttendance = attendanceData
      .filter((record) => {
        const attendanceDate = new Date(record.attendance_Date);
        return (
          attendanceDate.getDate() === targetDate.getDate() &&
          attendanceDate.getMonth() === targetDate.getMonth() &&
          attendanceDate.getFullYear() === targetDate.getFullYear()
        );
      })
      .sort(
        (a, b) =>
          new Date(b.attendance_Date).getTime() -
          new Date(a.attendance_Date).getTime()
      ); // Sort by latest attendance

    if (todayAttendance.length === 0) {
      //   console.log("No attendance marked for today.");/
      setNoAttendanceMArked(true);
      return;
    }
    setNoAttendanceMArked(false);
    const lastAttendance = todayAttendance[0];
    const lastAttendanceDate = new Date(lastAttendance.attendance_Date);
    const lastAttendanceTime =
      lastAttendanceDate.getHours() + lastAttendanceDate.getMinutes() / 60;

    // Find the slot of the last attendance
    const lastSlot = timeSlots.find(
      (slot) =>
        lastAttendanceTime >= slot.start && lastAttendanceTime < slot.end
    );

    // console.log("lastSlot",lastSlot)

    if (lastSlot) {
    //   console.log(
    //     `You are editing the attendance of the last slot: ${lastSlot.label}`
    //   );
    setEditSlotTime(
      `You are editing the attendance of the slot: ${lastSlot.label}`
    );
    }

    // Check if we are before the next attendance slot
    const nextSlot = timeSlots.find((slot) => currentTime < slot.start);

    const isAfterLastSlotOfDay =
      currentTime >= timeSlots[timeSlots.length - 1].start;

    if (
      (nextSlot && lastAttendanceTime < nextSlot.start) || (lastAttendanceTime >= timeSlots[timeSlots.length - 1].start &&
      isAfterLastSlotOfDay)
    ) {
      setEligibleEditSlot(false);
      //   console.log(
      //     `Attendance can be edited. The next slot is: ${nextSlot.label}`
      //   );
    } else if (nextSlot) {
      setEligibleEditSlot(true);
      //   console.log(
      //     `Cannot edit attendance as we are now in or past the current slot: ${nextSlot.label}`
      //   );
    } else {
      setEligibleEditSlot(true);
      //   console.log("No more attendance slots available for today.");
    }
  }

   useEffect(() => {
     checkAndEditAttendance(teacherMarkAttendanceList);
   }, [teacherMarkAttendanceList]);

  return (
    <div>
      {isLoading ? (
        <Skeleton height={190} radius="md" />
      ) : noAttendanceMArked == true ? (
        <EEmpty title="No attendance marked for today." />
      ) : (
        <>
          <Grid className="flex">
            <Grid.Col>
              <div>
                <p className="mt-2 font-semibold">Slot</p>
                {editSlotTime}
              </div>
            </Grid.Col>
          </Grid>{" "}
          {lastAttendanceDetails?.length ? (
            <>
              {lastAttendanceDetails?.map((each, index) => (
                <Grid className="flex">
                  <Grid.Col span={3} className="flex gap-3 mt-3 ">
                    <div className="bg-slate-300 rounded-xl p-3 w-12 h-12 items-center justify-center">
                      <FaUser size={25} />
                    </div>
                    <div>
                      <p className="font-semibold text-md">
                        {each?.employee_Name}
                      </p>
                      <p className="text-sm">{each?.employee_Code}</p>
                    </div>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <div>
                      <p className="mt-2 font-semibold">Designation</p>
                      {each?.designation}
                    </div>
                  </Grid.Col>
                  <Grid.Col span={2}>
                    <Switch
                      checked={each?.is_Present}
                      disabled={eligibleEditSlot}
                      onChange={(event) =>
                        handleEditAttendanceChange(index, event)
                      }
                      color="teal"
                      size="md"
                      label={each?.is_Present ? "Presence" : "Absence"}
                      thumbIcon={
                        each?.is_Present ? (
                          <BsCheckCircle color="dark" size={"0.8"} />
                        ) : (
                          <BsXCircle size="0.8rem" />
                        )
                      }
                    />
                  </Grid.Col>
                </Grid>
              ))}
              <Grid>
                <Grid.Col span={3}></Grid.Col>
                <Grid.Col span={1}>
                  <div className="flex gap-4 p-4">
                    <EButton onClick={() => {}} className="btn-light">
                      Cancel
                    </EButton>
                    <EButton
                      loading={isBtnLoading}
                      onClick={() => handleSubmitLastAttendance()}
                      className="btn-blue"
                      disabled={eligibleEditSlot}
                    >
                      Update Attendance
                    </EButton>
                  </div>
                </Grid.Col>
              </Grid>
            </>
          ) : (
            <EEmpty title={"Attendance has been not mark yet"} />
          )}
        </>
      )}
    </div>
  );
}
