import moment from "moment";
import { DatePicker } from "@mantine/dates";
import { BsCalendar2Date } from "react-icons/bs";
import { HiPencilSquare } from "react-icons/hi2";
import {
  Badge,
  Checkbox,
  Modal,
  MultiSelect,
  Select,
  Skeleton,
  TextInput,
} from "@mantine/core";

import PageLayout from "../../../PageLayout";
import EditSchedule from "./Components/EditSchedule";
import CreateSchedule from "./Components/CreateSchedule";
import { EButton, EButtonTabs, EEmpty, EModal, EPageTitle, ETable } from "../../../libs/ui";

import useSchedulePeriodicAssessment from "./Hooks/useSchedulePeriodicAssessment";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { handleCheckDateRange, handleCheckEndDateRange } from "../../../utils/utility";

export default function SchedulePeriodicAssessment() {

  const navigate = useNavigate()

  const {
    classes,
    subjectList,
    competencyByClassSubjectAssessment,
    assessmentTypes,
    availableScheduleList,
    isLoadingSchedules,
    isSavingSchedule,
    isEditingSchedule,
    setIsEditingSchedule,
    selectedScheduleToEdit,
    handleEditScheduleForPeriodicAssessment,
    selectedScheduleToDelete,
    setSelectedScheduleToDelete,
    handleDeleteScheduleForPeriodicAssessment,
    handleNewScheduleParamsChangeForEdit,
    handleUpdateDatesForScheduleParamsForEdit,
  } = useSchedulePeriodicAssessment();

  return (
    <PageLayout>
      <EPageTitle
        title="Schedule Periodic Assessment"
        end={
          <EButton
            className="btn-light"
            onClick={() => navigate('/new-schedule-periodic')}
            rightIcon={<BsCalendar2Date />}
          >
            Create New Schedule
          </EButton>
        }
      />

      {isLoadingSchedules ? (
        <Skeleton height={30} radius="md" />
      ) : availableScheduleList.length ? (
        <ETable>
          <thead>
            <tr>
              <th>Assessment</th>
              <th>Grade & Subject</th>
              <th>From</th>
              <th>To</th>
              <th>No. of Questions</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {availableScheduleList?.map((each, index) => (
              <tr key={index + "schedule"}>
                <td>{each.assessment_Type || ""}</td>
                <td>
                  {each.class_Name} - {each.subject_Name}
                </td>
                <td>
                  {each.startDate
                    ? moment(each.startDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>
                  {each.endDate
                    ? moment(each.endDate).format("DD/MM/YYYY")
                    : ""}
                </td>
                <td>{each.total_Number_Of_Questions}</td>
                <td>
                  {each.startDate && each.endDate
                    ?
                    handleCheckDateRange(each.startDate)
                      ?
                      // Incoming
                      <div className="flex justify-around">
                        <HiPencilSquare
                          size={20}
                          className="text-green-500 hover:text-green-600 cursor-pointer"
                          // onClick={() => handleSetParametersForEditSchedule(each)}
                          onClick={() => navigate(`/edit-schedule-periodic/${each?.periodic_Assessment_Schedule_Id}`)}
                        />
                        <MdDelete
                          size={20}
                          className="text-red-500 hover:text-red-600 cursor-pointer"
                          onClick={() => setSelectedScheduleToDelete(each)}
                        />
                      </div>
                      :
                      handleCheckEndDateRange(each.endDate) ?
                        <Badge
                          color="red"
                        >Expired</Badge>
                        : <Badge
                          color="green"
                        >On Going</Badge>
                    :
                    <span className="text-slate-400 text-sm">Not Available</span>}
                </td>
              </tr>
            ))}
          </tbody>
        </ETable>
      ) : (
        <EEmpty
          title="No Schedules Found!"
          description="There is no existing schedule available"
        />
      )}

      {/* CREATE SCHEDULE */}
      {/* <EModal
        size="lg"
        title="Create Schedule"
        isOpen={isCreatingSchedule}
        setIsOpen={() => {
          setIsCreatingSchedule(false);
          setConfirmSaveSchedule(false);
          handleResetNewScheduleParams();
        }}
      >
        <CreateSchedule
          classes={classes}
          subjectList={subjectList}
          assessmentTypes={assessmentTypes}
          competencyByClassSubjectAssessment={competencyByClassSubjectAssessment}
          getCompetencyBasedOnClassAndSubject={getCompetencyBasedOnClassAndSubject}
          newScheduleParams={newScheduleParams}
          handleNewScheduleParamsChange={handleNewScheduleParamsChange}
          handleUpdateDatesForScheduleParams={
            handleUpdateDatesForScheduleParams
          }
        />

        <div className="mt-5 flex justify-end">
          <Checkbox
            label={"Are you sure you want to save?"}
            checked={confirmSaveSchedule}
            onClick={() => setConfirmSaveSchedule(!confirmSaveSchedule)}
          />
          <EButton
            className="btn-blue"
            loading={isSavingSchedule}
            disabled={!confirmSaveSchedule}
            onClick={() => handleSaveSchedulesForPeriodicAssessment()}
          >
            Save
          </EButton>
        </div>
      </EModal> */}

      {/* EDIT SCHEDULE */}
      <Modal
        size="xxl"
        title="Edit Schedule"
        opened={isEditingSchedule}
        onClose={() => {
          setIsEditingSchedule(false);
        }}
      >
        <EditSchedule
          classes={classes}
          subjectList={subjectList}
          assessmentTypes={assessmentTypes}
          competencyByClassSubjectAssessment={competencyByClassSubjectAssessment}
          selectedScheduleToEdit={selectedScheduleToEdit}
          handleNewScheduleParamsChangeForEdit={
            handleNewScheduleParamsChangeForEdit
          }
          handleUpdateDatesForScheduleParamsForEdit={
            handleUpdateDatesForScheduleParamsForEdit
          }
        />

        <div className=" mt-5 flex justify-end">
          {/* <Checkbox
            label={"Are you sure you want to save?"}
            checked={confirmSaveSchedule}
            onClick={() => setConfirmSaveSchedule(!confirmSaveSchedule)}
          /> */}
          <EButton
            className="btn-blue"
            loading={isSavingSchedule}
            // disabled={!confirmSaveSchedule}
            onClick={() => handleEditScheduleForPeriodicAssessment()}
          >
            Savesddsd
          </EButton>
        </div>
      </Modal>

      {/* DELETE SCHEDULE */}
      <EModal
        title="Are you sure you want to delete this schedule?"
        isOpen={
          selectedScheduleToDelete?.periodic_Assessment_Schedule_Id
            ? true
            : false
        }
        setIsOpen={() => setSelectedScheduleToDelete(undefined)}
      >
        <div className="flex justify-end">
          <EButton
            className="btn-light"
            loading={isSavingSchedule}
            onClick={() => handleDeleteScheduleForPeriodicAssessment()}
          >
            Yes
          </EButton>
        </div>
      </EModal>
    </PageLayout>
  );
}
