import React, { useContext, useEffect } from 'react'
import { EButton, EInfoPanel, EPageTitle, EVerticalFormSection } from '../../../../libs/ui';
import PageLayout from '../../../../PageLayout';
import { MultiSelect, Select, Skeleton } from '@mantine/core';
import { CSVLink } from 'react-csv';
import useMasterData from '../../../Hooks/useMasterData';
import useMentorMonitorClassroomObservation from './Hooks/useMentorMonitorClassroomObservation';
import moment from 'moment';
import { AppContext } from '../../../../AppContext';
import { ROLES } from '../../../../Routes/routes';

const MentorMonitorClassroomObservation = () => {

const { user, role } = useContext(AppContext);

     const {
       districts,
       getDistrictByStateId,
       blocks,
       getBlocksByDistrictId,
       classes,
       getClasses,
       getSubject,
       subjectList,
       spotCompetencyList,
       getSpotCompetencyList,
       getSession,
       sessions,
     } = useMasterData();

     const {
       selectedMonth,
       setSelectedMonth,
       selectedSession,
       setSelectedSession,
       userMonitorValue,
       setUserMonitorValue,
       handleChangeUserMonitor,
       userManageRoles,
       userManageDesignation,
       handelClearFilter,
       isLoading,
       fetchSubjectWiseMentorClassroomObservation,
       mentorWiseClassroomObservationReport,
       mentortWiseClassroomObCSV,
     } = useMentorMonitorClassroomObservation();

       useEffect(() => {
         getSubject();
       }, []);
  return (
    <PageLayout>
      <EPageTitle title="Monitor Classroom Observation Reports" />
      <div>
        {/* FILTERS */}
        <div className="filter-container">
          <div className="flex justify-between w-full ">
            <div className="flex gap-4 flex-wrap">
              <Select
                label="Select Session"
                placeholder="Select month"
                data={[
                  { label: "2023-2024", value: "2024" },
                  { label: "2024-2025", value: "2025" },
                ]}
                value={selectedSession}
                onChange={(e) => setSelectedSession(String(e))}
              />

              <Select
                label="Select month"
                placeholder="Select month"
                data={[
                  {
                    label: "April",
                    value: "04",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 4
                        ? true
                        : false,
                  },
                  {
                    label: "May",
                    value: "05",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 5
                        ? true
                        : false,
                  },
                  {
                    label: "June",
                    value: "06",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 6
                        ? true
                        : false,
                  },
                  {
                    label: "July",
                    value: "07",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 7
                        ? true
                        : false,
                  },
                  {
                    label: "August",
                    value: "08",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 8
                        ? true
                        : false,
                  },
                  {
                    label: "September",
                    value: "09",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 9
                        ? true
                        : false,
                  },
                  {
                    label: "October",
                    value: "10",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 10
                        ? true
                        : false,
                  },
                  {
                    label: "November",
                    value: "11",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 11
                        ? true
                        : false,
                  },
                  {
                    label: "December",
                    value: "12",
                    disabled:
                      selectedSession ==
                        moment().add(1, "year").format("YYYY") &&
                      parseInt(moment().format("MM"), 10) < 12
                        ? true
                        : false,
                  },
                  {
                    label: "January",
                    value: "01",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "February",
                    value: "02",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                  {
                    label: "March",
                    value: "03",
                    disabled:
                      selectedSession == moment().add(1, "year").format("YYYY")
                        ? true
                        : false,
                  },
                ]}
                value={selectedMonth}
                onChange={(e) => setSelectedMonth(String(e))}
              />

              <Select
                label="Subject"
                // size="md"
                // required
                placeholder="Select"
                // className="w-1/5 grow"
                // value={userMonitorValue?.subject?.map((e: any) => String(e))}
                value={userMonitorValue?.subject}
                data={subjectList
                  ?.filter((e) => e.name === "Math" || e.name === "Hindi")
                  ?.map((e) => {
                    return {
                      value: String(e?.id),
                      label: e?.name,
                    };
                  })}
                onChange={(e: any) => {
                  handleChangeUserMonitor({ name: "subject", value: e });
                }}
              />

              <Select
                label="Role"
                placeholder="Select Role"
                disabled={true}
                // className=" w-1/5 grow"
                value={userMonitorValue?.role}
                data={userManageRoles?.map((each: any) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                onChange={(e: any) => {
                  handleChangeUserMonitor({ name: "role", value: e });
                  handleChangeUserMonitor({
                    name: "designation",
                    value: null,
                  });
                }}
              />

              {/* <Select
                label="Designation"
                placeholder="Select Designation"
                data={userManageDesignation?.map((each: any) => {
                  return {
                    label: each.name,
                    value: String(each.id),
                  };
                })}
                value={userMonitorValue?.designation}
                onChange={(e: any) =>
                  handleChangeUserMonitor({
                    name: "designation",
                    value: e,
                  })
                }
              /> */}
            </div>
            <div className="flex gap-3 pt-6">
              <EButton
                className="btn-light"
                disabled={
                  !userMonitorValue?.role ||
                  !userMonitorValue?.designation ||
                  !userMonitorValue?.subject
                    ? true
                    : false
                }
                onClick={handelClearFilter}
              >
                Clear
              </EButton>
              <EButton
                className="btn-blue"
                disabled={!selectedMonth}
                loading={isLoading}
                onClick={() => {
                  fetchSubjectWiseMentorClassroomObservation();
                }}
              >
                Search
              </EButton>
            </div>
          </div>
        </div>

        {/* DATA */}
        <div className="mt-5">
          {isLoading ? (
            <Skeleton radius={"md"} height={250} />
          ) : mentorWiseClassroomObservationReport.length ? (
            <EVerticalFormSection
              title={`Report on Monitor Classroom Observation for ${moment(
                selectedMonth
              ).format("MMM")}-${moment().format("YYYY")}`}
              rightPanel={<></>}
            >
              <div>
                <EInfoPanel title=" Your report is ready to download, Please click on Download File to download report">
                  <CSVLink
                    className="text-blue-600 text-sm hover:underline"
                    data={mentortWiseClassroomObCSV?.content ?? []}
                    headers={mentortWiseClassroomObCSV?.headers ?? []}
                    filename={`Report_on_Monitor_Classroom_Observation_for_${moment(
                      selectedMonth
                    ).format("MMM")}-${moment().format("YYYY")}.csv`}
                  >
                    Download File
                  </CSVLink>
                </EInfoPanel>
              </div>
            </EVerticalFormSection>
          ) : (
            <EInfoPanel title="Please select a month and press search to generate report" />
          )}
        </div>
      </div>
    </PageLayout>
  );
}

export default MentorMonitorClassroomObservation