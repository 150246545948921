import React, { useContext } from 'react'
import PageLayout from '../../../../PageLayout'
import { EButton, EInfoPanel, EPageTitle, ETable, EVerticalFormSection } from '../../../../libs/ui'
import { Input, Select, Skeleton } from '@mantine/core'
import useTeacherTrainingReport from './hook/useTeacherTrainingReport'
import { AppContext } from '../../../../AppContext'
import { ROLES } from '../../../../Routes/routes'
import { BiDownload } from 'react-icons/bi'
import { CiSearch } from 'react-icons/ci'

export default function TeacherTrainingReport() {

    const {
      isLoading,
      districts,
      selectedDistrict,
      setSelectedDistrict,
      selectedBlock,
      setSelectedBlock,
      blocks,
      clusterSchools,
      selectedCluster,
      setSelectedCluster,
      handleClear,
      fetchTrainingData,
      trainingData,
      reportLevel,
      trainingTitle,
      selectedTitle,
      setSelectedTitle,
      isLoadingSchedule,
      isLoadingPre,
      getTeacherTrainingScheduleReport,
      getTeacherTrainingTestReport,

      currentPage,
      setCurrentPage,
      itemsPerPage,
      nextPage,
      prevPage,
      totalRecords,
      setSearchQuery,
      searchQuery,
      currentItems,
    } = useTeacherTrainingReport();
    const {
        user,
        role
    } = useContext(AppContext)


  const totalPages = Math.ceil(totalRecords / itemsPerPage);
  const maxPageNumbers = 5; // Adjust this to 5 or 10 based on your requirement

  let startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
  let endPage = Math.min(totalPages, startPage + maxPageNumbers - 1);

  // Ensure that the displayed pages are within the correct range
  if (endPage - startPage + 1 < maxPageNumbers) {
    if (currentPage <= Math.ceil(maxPageNumbers / 2)) {
      endPage = Math.min(maxPageNumbers, totalPages);
    } else if (currentPage > totalPages - Math.floor(maxPageNumbers / 2)) {
      startPage = Math.max(1, totalPages - maxPageNumbers + 1);
    } else {
      startPage = Math.max(1, currentPage - Math.floor(maxPageNumbers / 2));
      endPage = Math.min(
        totalPages,
        currentPage + Math.floor(maxPageNumbers / 2)
      );
    }
  }




    return (
      <PageLayout>
        <EPageTitle title="Teacher Training Report" />
        {/* Filter */}
        <div className="filter-container gap-3">
          <Select
            label="Select District"
            placeholder="Select district"
            clearable
            disabled={
              role?.includes(ROLES.BLOCK) || role?.includes(ROLES.DISTRICT)
                ? true
                : false
            }
            data={districts?.map((each) => {
              return {
                label: each.name,
                value: String(each.id),
              };
            })}
            value={selectedDistrict ?? null}
            onChange={(e: any) => {
              setSelectedBlock(undefined);
              setSelectedCluster(undefined);
              setSelectedDistrict(e);
            }}
          />
          <Select
            label="Select Block"
            placeholder="Select block"
            clearable
            disabled={
              !selectedDistrict || role?.includes(ROLES.BLOCK) ? true : false
            }
            data={blocks?.map((each) => {
              return {
                label: each.name,
                value: String(each.id),
              };
            })}
            value={selectedBlock ?? null}
            onChange={(e: any) => {
              setSelectedCluster(undefined);
              setSelectedBlock(e);
            }}
          />
          {/* <Select
                    label="Select cluster"
                    placeholder="Select cluster"
                    clearable
                    disabled={!selectedBlock || role?.includes(ROLES.BLOCK) ? true : false}
                    data={
                        clusterSchools?.map(each => {
                            return {
                                label: each.cluster_School_Name,
                                value: String(each.cluster_School_Id)
                            }
                        })
                    }
                    value={selectedCluster ?? null}
                    onChange={(e: any) => setSelectedCluster(e)}
                /> */}

          <Select
            label="Select Title"
            placeholder="Select Title"
            clearable
            // disabled={
            //   !selectedBlock || role?.includes(ROLES.BLOCK) ? true : false
            // }
            data={trainingTitle?.map((each: any) => {
              return {
                label: each.Schedule_Header_Title,
                value: String(each.Training_Schedule_Header_Id),
              };
            })}
            value={selectedTitle ?? null}
            onChange={(e: any) => setSelectedTitle(e)}
          />

          <div className="flex gap-3 items-end justify-end grow">
            <EButton
              className="btn-light"
              disabled={
                !selectedDistrict ||
                role?.includes(ROLES.BLOCK) ||
                role?.includes(ROLES.DISTRICT)
                  ? true
                  : false
              }
              onClick={handleClear}
            >
              Clear
            </EButton>
            <EButton
              className="btn-blue"
              // disabled={!selectedMonth}
              loading={isLoading}
              onClick={fetchTrainingData}
            >
              Search
            </EButton>
          </div>
        </div>

        <div className="mt-8">
          {!trainingData.length ? null : (
            <div className="flex justify-end mb-4">
              <EButton
                className="btn-blue mr-4"
                loading={isLoadingSchedule}
                onClick={() => getTeacherTrainingScheduleReport()}
              >
                Training Schedule Report{" "}
                <BiDownload className="ml-2" size={22} />
              </EButton>

              <EButton
                className="btn-blue"
                loading={isLoadingPre}
                onClick={() => getTeacherTrainingTestReport()}
              >
                Training Test Report <BiDownload className="ml-2" size={22} />
              </EButton>
            </div>
          )}
          {isLoading ? (
            <Skeleton radius={"md"} height={250} />
          ) : !trainingData.length ? (
            <EInfoPanel title="Please select filter and press search to generate report" />
          ) : (
            <>
              <Input
                className="w-1/4 ml-auto hidden"
                icon={<CiSearch />}
                placeholder="Search"
                value={searchQuery}
                onChange={(e: any) => setSearchQuery(e.target.value)}
              />

              <ETable>
                <thead>
                  <tr>
                    <th>Sr. no.</th>
                    {/* <th>{reportLevel || "-"}</th> */}
                    <th>District</th>
                    <th>Block</th>
                    <th>Training Title</th>
                    <th>Total Employee</th>
                    <th>Total Employee Selection</th>
                    <th>Attendance</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {trainingData?.map((e, index) => (
                    <tr>
                      <td>{index + 1}</td>
                      {/* <td>{e?.Name || "-"} </td> */}
                      <td>{e?.district_Name || "-"} </td>
                      <td>{e?.block_Name || "-"}</td>
                      <td>{e?.training_Title || "-"}</td>
                      <td>{e?.total_Employee || "-"}</td>
                      <td>{e?.total_Employee_Selection || "-"}</td>
                      <td>{e?.attendance || "-"}</td>
                      <td>{e?.training_Start_Date ?? "-"}</td>
                      <td>{e?.training_End_Date ?? "-"}</td>
                    </tr>
                  ))}
                </tbody>
              </ETable>

              <div className="text-end mt-2">
                {/* Pagination controls */}
                <EButton
                  className="btn-blue"
                  onClick={prevPage}
                  disabled={currentPage === 0}
                >
                  Previous
                </EButton>

                {/* {pages.map(page => (
              <EButton
                key={page}
                onClick={() => setCurrentPage(page)}
                className={currentPage === page ? 'btn-blue ml-2' : 'btn-light ml-2'}
              >
                {page}
              </EButton>
            ))} */}

                {/* Render first page */}
                {startPage > 1 && (
                  <>
                    <EButton
                      onClick={() => setCurrentPage(0)}
                      className="btn-light ml-2"
                    >
                      1
                    </EButton>
                    {startPage > 2 && <span className="ml-2">...</span>}
                  </>
                )}

                {/* Render page numbers */}
                {Array.from(
                  { length: endPage - startPage + 1 },
                  (_, index) => startPage + index
                ).map((page) => (
                  <EButton
                    key={page}
                    onClick={() => setCurrentPage(page - 1)}
                    className={
                      currentPage === page - 1
                        ? "btn-blue ml-2"
                        : "btn-light ml-2"
                    }
                  >
                    {page}
                  </EButton>
                ))}

                {/* Render last page */}
                {endPage < totalPages && (
                  <>
                    {endPage < totalPages - 1 && (
                      <span className="ml-2">...</span>
                    )}
                    <EButton
                      onClick={() => setCurrentPage(totalPages - 1)}
                      className={
                        currentPage === totalPages - 1
                          ? "btn-blue ml-2"
                          : "btn-light ml-2"
                      }
                    >
                      {totalPages}
                    </EButton>
                  </>
                )}

                <EButton
                  className="btn-blue ml-2"
                  onClick={nextPage}
                  // disabled={allUserManagement.length < itemsPerPage || allUserManagement.length === 0}
                  disabled={
                    currentPage >= totalPages - 1 || // Disable if on the last page
                    (trainingData?.length < itemsPerPage &&
                      currentPage === totalPages - 1) // Disable if fewer items on the last page
                  }
                >
                  Next
                </EButton>
              </div>
            </>
          )}
        </div>
      </PageLayout>
    );
}
